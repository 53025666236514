export default {
  text: '#95a6ab',
  text2: '#457081',
  primary: '#139dca',
  secondary: '#36474e',
  background: '#fdfdfd',
  backgroundSecondary: 'lightgrey',
  light: '#fdfdfd',
  dark: '#484848'
}
