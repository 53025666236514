export default {
  heading: 'Playfair Display, serif',
  body: 'Montserrat, san-serif',
  monospace: 'Menlo, monospace',
  display: 'Antonio, sans-serif',
  googleFonts: [
    'Raleway:100,200,300,400,500,600,700,800,900',
    'Playfair Display:400,500,600,700,800,900',
    'Antonio',
    'Montserrat:100,200,300,400,500,600,700,800,900'
  ]
  // customFamilies: [''],
  // customUrls: [''],
}
