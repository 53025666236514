export default {
  navigation: {
    zIndex: '9999 !important',
    '.container': {
      zIndex: '9999 !important',
      padding: ['1rem 1rem 1rem 2rem', '', '1rem 2rem', '1rem 2rem', '1rem 3rem', '1rem 15vw'],
      color: 'white',
      '.navItemDropdownMenu': {
        backgroundColor: 'transparent'
      }
    },

    '.containerScrolled': {
      zIndex: '9999 !important',
      padding: ['0.5rem 0.5rem 0.5rem 1rem', '', '0.5rem 2rem', '0.5rem 2rem', '0.5rem 3rem', '0.5rem 15vw'],
      backgroundColor: 'background',
      color: 'black',
      '.locationSwitcher': {
        justifyContent: 'center',
        button: {
          fontSize: '0.8rem'
        }
      },
      '.popUp': {
        transform: 'unset'
      },
      '.navItemDropdownMenu': {
        backgroundColor: 'background'
      }
    },

    '.logoLocationContainer': {
      margin: ['0rem auto 0rem 0rem', '', '', ''],
      padding: '0rem',
      position: ['', '', '', 'static'],
      '.container': {},
      '.logo': {
        maxHeight: ['125px', '150px'],
        padding: '0rem',
        img: {
          padding: '0rem',
          filter: 'unset',
          maxHeight: ['125px', '150px']
        }
      },
      '.logoScrolled': {
        maxHeight: '100px',
        padding: '0rem',
        img: {
          padding: '0rem',
          filter: 'unset',
          maxHeight: '100px'
        }
      },

      '.locationSwitcher': {
        justifyContent: 'center',
        button: {
          fontSize: '0.8rem'
        }
      },
      '.popUp': {
        transform: 'unset'
      }
    },

    '.smallNavMenu': {
      margin: 'auto',
      '.navItem': {
        color: 'inherit',
        margin: '0rem',
        p: '0.25rem 1rem',
        a: {
          fontSize: '1rem',
          fontFamily: 'body',
          fontWeight: '400',
          letterSpacing: '0px',
          transition: 'all ease-in-out 0.5s',
          ':hover': {
            color: 'primary',
            textDecoration: 'underline'
          }
        }
      },
      '.book-now': {
        a: {
          padding: '0.75rem 1.5rem',
          border: 'solid 1px',
          borderColor: 'inherit',

          ':hover': {
            backgroundColor: 'primary',
            color: 'white',
            borderColor: 'primary',
            textDecoration: 'none'
          }
        }
      },
      '.gift-cards': {
        a: {
          padding: '0.75rem 1.5rem',
          border: 'solid 1px',
          borderColor: 'inherit',

          ':hover': {
            backgroundColor: 'primary',
            color: 'white',
            borderColor: 'primary',
            textDecoration: 'none'
          }
        }
      }
    },
    '.hamburger': {
      color: 'inherit',
      ml: '1.5rem',
      mr: '0rem',

      backgroundColor: 'transparent',
      borderColor: 'inherit',
      div: {
        height: '3px',
        backgroundColor: 'currentColor'
      }
    },
    '.navMenu': {
      width: ['85%', '60%', '50%', '40%'],
      left: '-100vw',
      top: '0rem',
      right: 'unset',
      transform: 'unset',
      position: 'fixed',
      color: 'white',
      backgroundColor: 'transparent',
      height: '100vh',
      alignItems: ['flex-start', 'flex-start', 'flex-start', 'flex-start'],
      '.navItem': {
        textAlign: 'left',
        a: {
          fontSize: '1.5rem',
          letterSpacing: '0px',
          fontWeight: 'bold'
        }
      }
    },
    '.navMenuOpen': {
      '::before': {
        content: "'Pages'",
        padding: '0.5rem',
        mb: '1.5rem',
        fontWeight: 'bold',
        fontSize: '1.25rem',
        opacity: '0.5',
        borderBottom: '1px solid',
        textTransform: 'uppercase'
      },
      color: 'white',
      backgroundColor: 'transparent',
      padding: ['', '', '', '2rem'],
      width: ['100%', '100%', '100%', '100%'],
      alignItems: ['flex-start', 'flex-start', 'flex-start', 'flex-start'],
      transform: 'unset',
      position: 'fixed',
      left: '0rem',
      right: 'unset',
      '.navItem': {
        textAlign: 'left',
        a: {
          fontSize: ['1.5rem', '1.75rem', '2.5rem', '3rem'],
          letterSpacing: '0px',
          fontFamily: 'body',
          fontWeight: 'bold',
          color: 'white',
          ':hover': {
            color: 'secondary'
          }
        }
      },
      '.seperatorLine': {
        display: 'none'
      },

      '.navItemDropdown': {
        display: 'flex',
        flexDirection: 'column'
      },

      '.navItemDropdownMenu': {
        position: 'static',
        width: 'fit-content',
        background: 'transparent',
        textShadow: 'unset',
        '.navItem': {
          ml: '1rem'
        }
      }
    },

    '.phoneSocialContainer': {
      mt: '4rem',
      flexDirection: 'column',
      alignItems: 'flex-start',
      opacity: '0.5',

      '> li': {
        width: 'fit-content',
        fontWeight: 'bold',

        a: {
          fontSize: '20px'
        },
        '.fa-phone': {
          mr: '1rem'
        },
        '.socialLink': {
          svg: {
            width: '26px',
            height: '26px'
          }
        }
      }
    },
    '.navBlock': {
      width: ['15%', '40%', '50%', '60%'],
      left: '-100vw',
      top: '0rem',
      right: 'unset',
      height: '100vh',
      position: 'fixed'
    },
    '.navBlockOpen': {
      position: 'fixed',
      backdropFilter: 'blur(1000042px)',
      backgroundColor: '#2f5f84',
      opacity: 0.99,
      backgroundSize: 'cover'
    },
    '.navMenuLogo, .smallNavHeading': {
      display: 'none'
    }
  },

  footer: {
    '> .section': {
      padding: ['2rem 1rem 2rem 3.5rem', '', '2rem']
    },
    borderTop: '2px solid',
    '.logoContainer .logo': {
      maxHeight: '90px',
      width: 'auto'
    }
  },

  ctaWidget: {
    display: 'none',
    a: {
      backgroundColor: 'secondary'
    }
  },
  contactForm: {
    padding: '4rem 1rem',
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.5rem',
      color: 'text2',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      marginBottom: '2rem'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '4rem'
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },
      '.submitBtn': {
        variant: 'buttons.primary'
        // borderRadius: '0px',
      }
    }
  },

  // ? ==================
  // ? === Typography ===
  // ? ==================

  title: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: ['2rem', '', '2.5rem', '3rem', '3.5rem', '4rem'],
    order: '2',
    fontWeight: '300',
    whiteSpace: 'pre-wrap',
    color: 'text2',
    mb: '1.5rem'
  },
  subtitle: {
    fontWeight: '300',
    textTransform: 'initial',
    order: '1',
    fontFamily: 'body',
    fontSize: '1.25rem',
    textTransform: 'uppercase',
    mb: '0.75rem'
  },

  text: {
    lineHeight: '1.85',
    order: '3',
    mb: '1.5rem',
    '*': {
      fontSize: 'inherit',
      lineHeight: 'inherit'
    }
  },

  linksContainer: {
    order: '4',
    justifyContent: 'flex-start',
    '.ctaButton': {
      variant: 'buttons.primary'
    },
    '.secondaryCtaButton': {
      variant: 'buttons.secondary'
    }
  },

  sideBySide1: {
    padding: ['1rem', '1.5rem', '2rem', '2.5rem', '3rem', '4rem'],
    '.lazyload-wrapper': {
      maxHeight: '70vh'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text',
      maxWidth: '580px'
    },
    '.linksContainer': {
      variant: 'customVariants.linksContainer'
    }
  },

  // ? ==================
  // ? === Components ===
  // ? ==================

  homepageHero: {
    img: {
      filter: 'brightness(0.7)'
    },
    '.hero_content_container': {
      position: 'absolute',
      top: 'unset',
      bottom: '15%',
      left: '50%',
      transform: 'translateX(-50%)',
      margin: '0rem',
      width: '100%',
      maxWidth: '767px',
      padding: '2rem',
      color: 'white',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      '.title': {
        variant: 'customVariants.title',
        fontSize: ['2rem', '2.5rem', '3rem', '3.5rem', '4rem', '4.5rem'],
        order: '1',
        color: 'white',
        mb: '2rem'
      },
      '.subtitle': {
        borderTop: '2px solid',
        borderColor: 'primary',
        width: 'fit-content',
        pt: '1.5rem',
        variant: 'customVariants.subtitle',
        fontSize: ['1.25rem', '1.5rem', '1.75rem', '2rem', '2.25rem', '2.5rem'],
        order: '2',
        opacity: '0.8'
      },
      '.text': {
        variant: 'customVariants.text',
        order: '3'
      },
      '.linksContainer': {
        order: '4'
      }
    }
  },
  homepageHeroShout: {
    display: 'none'
  },

  homepageShout: {},

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  featuredServicesTitle: {
    variant: 'customVariants.sideBySide1',
    pb: '0rem !important',
    'h2.title': {
      variant: 'text.title',
      color: 'text2',
      borderBottom: '4px solid',
      borderColor: 'black',
      width: 'fit-content',
      m: 'auto',
      mb: '1.5rem'
    }
  },

  homepageSlider: {
    '.mainTextContainer': {
      display: 'none'
    },
    backgroundColor: '#f8ffff',
    '.slick-slide': {
      padding: [
        '0rem 2rem 2rem',
        '0rem 3rem 3rem',
        '0rem 4rem 4rem',
        '0rem 5rem 5rem',
        '0rem 6rem 6rem',
        '0rem 7rem 7rem'
      ]
    },
    '.slick-arrow': {
      backgroundColor: 'secondary',
      color: 'white',
      borderRadius: '100%',
      p: '0.5rem',
      width: '20px',
      height: '20px'
    },
    '.slick-prev': {
      left: ['0.5rem', '', '1rem']
    },
    '.slick-next': {
      right: ['0.5rem', '', '1rem']
    },
    '.slideBox': {
      display: 'flex',
      flexDirection: ['column', '', 'row'],
      justifyContent: 'center',
      alignItems: 'center',
      '.imageContainer': {
        width: ['100%', '100%', '50%']
      },
      '.textContent': {
        width: ['100%', '100%', '50%'],
        padding: ['1rem', '1.5rem', '2rem', '2.5rem', '3rem', '4rem'],
        '.title': {
          variant: 'customVariants.title',
          borderBottom: '1px solid',
          pb: '1rem',
          borderColor: 'primary'
        },
        '.subtitle': {
          variant: 'customVariants.subtitle'
        },
        '.text': {
          variant: 'customVariants.text',
          fontSize: ['1rem', '1.1rem', '1.2rem']
        },
        '.linksContainer': {
          variant: 'customVariants.linksContainer'
        }
      }
    }
  },

  homepageBoxes: {
    padding: ['0rem', '', '1rem', '1.5rem', '2rem', '3rem'],
    '.box': {
      margin: ['1rem', '', '1rem', '1.5rem', '2rem', '3rem'],
      width: ['100%', '', 'calc(50% - 2rem)', 'calc(50% - 3rem)', 'calc(50% - 4rem)', 'calc(50% - 6rem)'],
      position: 'relative',
      height: ['auto', '', '50vh', '', '50vh'],
      background: 'transparent',
      color: 'white',

      borderRadius: '0px',
      justifyContent: 'flex-end'
    },
    '.image': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      height: '100%',
      width: '100%',
      mb: '0rem',
      zIndex: '-1',
      objectFit: 'cover',
      filter: 'brightness(0.75)'
    },
    '.title': {
      variant: 'customVariants.title',
      padding: '2rem 2rem 0rem',
      order: '1',
      fontSize: ['1.5rem', '1.75rem', '2rem', '', '2.25rem'],
      color: 'white',
      borderBottom: '2px solid',
      borderColor: 'primary',
      width: 'fit-content',
      pb: '1rem'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      padding: '2rem 2rem 0rem',
      order: '2',
      fontSize: ['1.5rem', '1.75rem', '2rem', '', '2.25rem'],
      color: 'white'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      padding: '0rem 2rem',
      maxWidth: '600px',
      order: '3'
    },
    '.linksContainer': {
      variant: 'customVariants.linksContainer',
      padding: '0rem 2rem'
    }
  },

  homepageTestimonials: {
    padding: ['6rem 2rem', '6rem 2rem', '6rem 2rem', '7rem 5%'],
    maxWidth: 'unset',
    minHeight: '90vh',
    backgroundImage:
      'url(https://res.cloudinary.com/gonation/image/upload/o_20/v1700508297/sites/j-house-spa/pexels-dagmara-dombrovska-10860854.jpg)',
    backgroundSize: 'cover',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '::before': {
      content: "'”'",
      fontSize: '13rem',
      lineHeight: '0.5',
      color: 'primary'
    },
    '.innerMenuContainer': {
      height: 'fit-content'
    },
    '.menuSectionTitle': {
      variant: 'text.title',
      padding: ['0rem', '0rem', '0rem'],
      fontSize: ['2rem', '2.5rem', '3rem', '4.5rem', '4rem'],
      margin: '0rem auto 2rem',
      textAlign: 'center',
      color: 'text2',
      borderBottom: '4px solid',
      borderColor: 'black',
      width: 'fit-content',
      mb: '3rem'
    },

    '.menuItemContentContainer': {
      padding: '0rem'
    },

    '.menuSectionDescription': {
      variant: 'text.text',
      fontSize: '1rem'
    },

    '.menuItemContainer': {
      margin: '0rem',
      width: '100%',
      textAlign: 'center'
    },

    '.menuItemNameContainer': {
      variant: 'text.subtitle',
      color: 'secondary',
      padding: ['0rem', '0rem', '0rem'],
      margin: '0rem',
      mb: '1rem',
      textAlign: 'center'
    },

    '.menuItemName': {
      fontSize: ['1.25rem', '1.25rem', '1.25rem', '1.5rem'],
      textAlign: 'center',
      width: '100%',
      color: 'primary'
    },

    '.menuItemDescription': {
      variant: 'text.text',
      padding: ['0rem', '0rem', '0rem', '0%'],
      width: '100%',
      textAlign: 'center',
      maxWidth: '767px',
      mx: 'auto',
      lineHeight: '1.8',
      mb: '3rem',
      color: 'text2'
    },

    '.slick-slider': {
      '.slick-arrow': {
        position: 'absolute',
        top: 'unset',
        bottom: '-3rem',
        left: '50%',
        transform: 'translateX(-100%)',
        border: 'solid 1px',
        width: '40px',
        height: '40px',
        padding: '0.5rem'
      },
      '.slick-dots': {
        width: '100%',
        bottom: '1rem'
      },
      '.slick-next': {
        left: '50%',
        transform: 'unset'
      }
    }
  },
  homepageContact: {
    minHeight: '70vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '.section': {
      backgroundColor: 'rgba(255,255,255,0.8)',
      padding: '2rem 1rem',
      height: 'fit-content'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text',
      maxWidth: '580px'
    },
    '.linksContainer': {
      variant: 'customVariants.linksContainer'
    }
  },

  //  ? ====  About page  ====

  aboutSection: {
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    '.linksContainer': {
      variant: 'customVariants.linksContainer'
    }
  },

  //  ? ====  products page  ====

  services: {
    '.innerMenuContainer': {
      backgroundImage:
        'url(https://res.cloudinary.com/gonation/image/upload/o_15/a_180/v1700599475/sites/j-house-spa/pexels-jess-bailey-designs-1172849.jpg)',
      backgroundSize: 'cover',
      backgroundPosition: 'left bottom',
      backgroundRepeat: 'no-repeat'
    },
    maxWidth: 'unset',
    p: ['0rem', '0rem', '0rem'],

    '.tabsRow': {
      backgroundColor: 'secondary',
      justifyContent: ['flex-start', '', 'center'],
      padding: '1rem'
    },

    '.menuTab': {
      border: 'none',
      fontSize: '0.8rem',
      borderLeft: '1px solid',
      borderColor: 'white',
      padding: '0rem 0.5rem',
      margin: '0.5rem 0rem',
      width: ['50%', '', '25%', '25%', '15%'],
      maxWidth: 'unset',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    },
    '.menuTabActive': {
      backgroundColor: 'transparent',
      textDecoration: 'underline'
    },

    '.menuSectionTitle': {
      variant: 'customVariants.title',
      textTransform: 'initial',
      my: '2rem'
    },
    '.menuSectionDescription': {
      variant: 'customVariants.subtitle',
      textTransform: 'initial',
      maxWidth: '590px',
      margin: '0 auto 3rem',
      fontSize: '1rem',
      fontWeight: '600'
    },
    '.allInContainerWrapper': {
      maxWidth: '1000px',
      margin: '0 auto'
    },
    '.menuItemsContainer': {
      maxWidth: '620px',
      margin: '0 auto'
    },
    '.menuItemInnerContainer': {
      flexDirection: ['column', '', 'row'],
      flexGrow: 'unset',
      mb: '0rem'
    },
    '.menuItemPrice': {
      fontWeight: '800',
      color: 'primary'
    },

    '.menuItemImageContainer': {
      maxWidth: ['unset', '', ''],
      minWidth: ['100%', '', '300px', '400px'],
      maxHeight: 'unset',
      height: ['300px', '', '', '400px'],
      width: ['100%', '', '300px', '400px']
    },
    '.menuItemName': {
      fontSize: '1.5rem',
      mb: '0rem',
      color: 'secondary',
      whiteSpace: 'pre-wrap',
      width: 'fit-content'
    },
    '.menuItemContentContainer': {
      width: 'inherit',
      flexGrow: 'unset'
    },
    '.dropdownContainer': {
      display: 'none'
    },
    '.section-cell': {
      width: ['100%', 'calc(50% - 2rem)', '', 'calc(33.3% - 2rem)'],
      ':hover': {
        '.cellImage': {
          transform: 'translate(-50%,-50%) scale(1.1)'
        }
      }
    },
    '.cellImageContainer': {
      overflow: 'hidden',
      '.imageFill': {
        paddingBottom: '75%'
      }
    },
    '.menuCell, .cellImage': {
      borderRadius: '0px',
      transition: 'all ease-in-out 0.5s'
    },
    '.menuCell': {
      border: 'solid lightgrey 1px',
      boxShadow: '1px 1px 20px 1px lightgrey'
    },
    '.cellName': {
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      fontFamily: 'heading'
    },
    '.menuItemContainerImgActive': {
      width: ['100%', '100%', '100%', '100%']
    },
    '.backToMenuBtn': {
      variant: 'buttons.primary',
      marginBottom: '3rem'
    }
  },

  //  ? ====  gallery page  ====

  gallery: {
    // '.albumsCTA ': {
    //   variant: 'buttons.secondary',
    // },
    '.albumsContainer': {
      display: 'none'
    },
    '.albumTitle': {
      variant: 'buttons.secondary',
      backgroundColor: 'transparent',
      color: 'black'
    }
  },

  locationMap: {
    h3: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.5rem', '', '1.75rem'],
      borderBottom: '2px solid',
      borderColor: 'primary',
      pb: '1rem'
    },
    '.dayContainer': {
      mb: '0.5rem'
    },
    '.dayofWeekText': {
      margin: '0 auto 0 0'
    }
  },

  //  ? ====  Contact page  ====

  contactForm: {
    order: '4',
    textAlign: 'left',
    position: 'relative',
    alignItems: 'flex-start',
    '> *': {
      zIndex: '1'
    },
    '::before': {
      content: ["''"],
      backgroundColor: ['rgb(81 148 175 / 87%)', '', 'rgb(81 148 175 / 87%)'],
      width: ['100%', '', '50%'],
      height: '100%',
      position: 'absolute',
      top: '0%',
      right: '0%',
      zIndex: '0'
    },
    '.title': {
      variant: 'text.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      maxWidth: '500px',
      width: '100%'
    },
    '.subtitle': {
      variant: 'text.subtitle',
      maxWidth: '500px',
      width: '100%',
      opacity: '0.7',
      mb: '2rem',
      fontFamily: 'body'
    },
    '.text': {
      maxWidth: '500px',
      width: '100%',
      mb: '2rem'
    },
    backgroundPosition: 'center center',
    color: 'white',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    pl: ['', '', '52.5%'],
    form: {
      maxWidth: '500px'
    },
    '.inputField, .textField ': {
      fontWeight: '300',
      border: 'none',
      borderBottom: '1px solid',
      borderColor: 'secondary',
      borderRadius: '0px',
      padding: '1.5rem 0.5rem',
      color: 'light',
      fontFamily: 'heading',
      '::placeholder': {
        color: 'white'
      }
    },
    textarea: {
      '::placeholder': {
        color: 'text',
        fontWeight: '300',
        fontFamily: 'heading'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      padding: '0.5rem 1rem',
      height: 'unset',
      width: 'auto',
      marginTop: '1.5rem',
      ':hover': {
        borderColor: 'dark',
        backgroundColor: 'dark'
      }
    }
  },
  teamMenu: {
    padding: ['0rem 1rem', '0rem 1rem', '0rem 1rem', '0rem 1rem'],

    my: '1rem',

    '.boxesHeading': {
      display: 'none'
    },
    '.boxesContainer': {
      margin: '0rem auto',
      flexWrap: 'wrap',
      justifyContent: 'center'
    },
    '.box': {
      width: ['', '', 'calc(50% - 1rem)', 'calc(25% - 4rem)'],
      flexGrow: 'unset',
      margin: ['1rem', '', '', '2rem'],
      borderRadius: '20px',
      borderColor: 'lightgrey',
      ':hover': {},
      a: {}
    },
    '.textContent': {
      position: 'static',
      '.title': {
        variant: 'customVariants.title',
        fontSize: ['1.5rem', '1.6rem', '1.7rem', '1.8rem'],
        mb: '0rem',
        order: '1',
        borderBottom: '1px solid',
        pb: '0.5rem',
        borderColor: 'primary',
        mb: '0.5rem',
        width: 'fit-content'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        color: 'text',
        textTransform: 'initial',
        color: 'black',
        order: '2'
      }
    }
  },
  teamPage: {
    '::before': {
      backgroundColor: 'text',
      content: "''",
      height: '190px'
    },
    '.imageContainer': {
      width: ['', '', '40%']
    },
    '.contentRow': {
      width: ['', '', '60%'],
      padding: ['1rem', '1.5rem', '2.5rem', '3rem']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    }
  }
}
