export default {
  primary: {
    color: 'white',
    border: 'solid 1px',
    borderColor: 'secondary',
    fontSize: '1rem',
    fontWeight: '700',
    textTransform: 'uppercase',
    paddingBottom: '0rem',
    padding: '0.7rem 2rem',
    transition: 'all 0.4s ease-in-out',
    backgroundColor: 'secondary',
    ':hover': {
      backgroundColor: 'primary',
      borderColor: 'primary',
      color: 'light'
    }
  },
  secondary: {
    color: 'white',
    border: 'solid 1px',
    borderColor: 'secondary',
    fontSize: '1rem',
    fontWeight: '700',
    textTransform: 'uppercase',
    paddingBottom: '0rem',
    padding: '0.7rem 2rem',
    transition: 'all 0.4s ease-in-out',
    backgroundColor: 'secondary',
    ':hover': {
      backgroundColor: 'primary',
      borderColor: 'primary',
      color: 'light'
    }
  }
}
